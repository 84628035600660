<template>
  <v-app id="inspire">
    <v-app-bar color="secondary" flat extended>
      <v-container class="py-0 fill-height">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <a
              href="/#/"
              style="text-decoration: none"
              v-bind="attrs"
              v-on="on"
            >
              <span class="d-inline-flex justify-start align-center">
                <v-avatar class="mr-2" size="48" tile elevation="3">
                  <img :src="require('./assets/Logo_ENI.svg')" alt="ENI" />
                </v-avatar>

                <v-responsive max-width="150">
                  <h1 class="text-left">VIRGILIO</h1>
                </v-responsive>
              </span>
            </a>
          </template>
          <span>torna a Home</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <app-bar-menu-user></app-bar-menu-user>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-4" style="margin-top: -64px">
      <v-container
        max-height="calc(100vh - 88px)"
        :fluid="intViewportWidth <= 1366 ? true : false"
      >
        <perfect-scrollbar ref="scroll">
          <router-view />
        </perfect-scrollbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { version } from "../package";
export default {
  name: "App",
  data: () => ({
    appVersion: version,
    intViewportWidth: window.innerWidth,
  }),
  components: {
    AppBarMenuUser: () => import("@/components/core/AppBarMenuUser"),
  },
  watch: {
    $route() {
      this.$refs.scroll.$el.scrollTop = 0;
    },
    intViewportWidth(val) {
      return val;
    },
  },
  mounted() {
    console.log("ver.: ", this.appVersion);
    window.onresize = () => {
      this.intViewportWidth = window.innerWidth;
    };
  },
};
</script>

<style>
@media print {
  @page {
    size: landscape;
    margin: 0;
  }

  *,
  html,
  body {
    overflow-y: visible !important;
  }

  html {
    zoom: 0.8;
  }

  .container,
  .fluid {
    max-width: 1900px !important;
  }
}

html {
  overflow: hidden !important;
}

ol.breadcrumb {
  padding: 5px 10px !important;
}
h1 {
  font-size: 2.3rem !important;
}
li a {
  color: white !important;
}
.breadcrumb-item.active {
  color: #fdd300 !important;
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1650px !important;
  }
}
.v-data-table > header {
  height: 0px !important;
}
.v-card > .v-card__title {
  padding: 5px 10px 4px 10px;
}
.col.col-12 {
  padding-top: 5px !important;
}
.backgroundColor {
  background-color: yellow !important;
}
.backgroundColorRed {
  background-color: red !important;
}
.backgroundColorWarning {
  background-color: #fdd300 !important;
}
.backgroundColorError {
  background-color: #ff5252 !important;
}
.breadcrumb {
  margin-bottom: 0 !important;
}
.text-top {
  vertical-align: initial !important;
}
.text-top > span {
  display: block;
  padding-top: 16px;
}
.dt-topologia-search-column .theme--light.v-label {
  color: #e0e0e0;
  font-size: 0.75rem;
}
.dt-topologia-search-column
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before,
.dt-topologia-search-column
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:after {
  border-color: #e0e0e0;
}
.dt-topologia-search-column
  .theme--light.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: #e0e0e0;
}
.dt-topologia-search-column .theme--light.v-input input,
.dt-topologia-search-column .theme--light.v-input textarea {
  color: #e0e0e0;
  font-size: 0.75rem;
  font-weight: bold;
}
th.text-start {
  background-color: #949494 !important;
}
i.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
  color: #deb800 !important;
  font-size: 40px;
}
.v-application p {
  margin-bottom: 0px !important;
}
col {
  padding: 5px !important;
}
.v-application--is-ltr .v-banner__wrapper {
  padding: 5px 8px 5px 24px !important;
}
.menuBtn {
  background: #deb800 !important;
  color: #444444 !important;
  height: 20px !important;
  width: 20px !important;
  min-width: 20px !important;
  padding: 0px 0px !important;
  font-size: 8px !important;
}
.menuBtn > span > i {
  font-size: 15px !important;
}
hr.v-divider.theme--light {
  margin: 0px !important;
}
.v-data-table-header__sort-badge {
  display: none !important;
}
.v-list.v-sheet.theme--light {
  min-width: 160px;
}
.iconSave {
  margin-left: -10px !important;
  margin-top: -30px !important;
  background: red;
  font-size: 18px !important;
  border-radius: 50px;
  padding: 3px;
}
footer.v-footer {
  font-size: 12px;
}
li.breadcrumb-item {
  font-size: 12px;
}
#tableDrilldownw .v-data-table-header {
  background-color: #fdd300 !important;
}
</style>
