export default {
  data() {
    return {
      activeplan: [],
    };
  },

  computed: {},
  created() {},
  methods: {
    async getActivePlan() {
      this.loading = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_OPLAN_PLAN_ACTIVE"),
          this.opts
        );
        this.activeplan = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
  },
};
