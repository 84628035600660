<template>
  <v-dialog
    v-model="dialogInfoExcel"
    scrollable
    width="500"
    @click:outside="closeDialogInfo()"
  >
    <v-card>
      <v-card-title>INFO</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p>
          Inserire nella prima colonna i nodi impattati secondo l'analisi
          sistemistica.
        </p>
        <p>
          Inserire nella seconda colonna i nodi impattati secondo l'analisi
          monitoraggio.
        </p>
        <p>
          Inserire nella terza colonna i servizi impattati, e nella quarta la
          relativa probabilità di impatto.
        </p>
        <p>
          Il delimitatore dei campi del file csv è il punto e virgola; la prima
          riga contiene gli header (non viene letta dal server).
        </p>
        <p>
          Nel caso siano presenti meno di 4 campi su una riga, vanno comunque
          riportati sempre i tre punti e virgola.
        </p>
        <p>
          <b>
            Il file csv, per essere correttamente interpretato, deve essere
            formattato in UTF-8
          </b>
        </p>
        <v-btn @click="exportExcel" color="secondary" dark>
          Scarica Modello
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialogInfo"> Chiudi </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
import download from "downloadjs";
export default {
  mixins: [connectionMixin],
  props: {
    dialogInfoExcel: {
      default: false,
      type: Boolean,
    },
    info: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeDialogInfo() {
      this.$emit("open-dialog-info", false);
    },
    async exportExcel() {
      try {
        const response = await this.$http.get(
          this.$env.value("API_EXPORT_EXCEL_PROVA"),
          this.optsXls
        );

        const content = response.headers["application/vnd.ms-Excel"];
        download(response.data, "Modello-Excel" + ".csv", content);
      } catch (error) {
        console.log(error);
        alert("Qualcosa è andato storto. ");
      }
    },
  },
};
</script>

<style scoped>
.v-list-item p {
  margin-bottom: 0px;
  margin-top: 8px;
}
</style>
