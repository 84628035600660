<template>
  <v-sheet
    rounded="lg"
    color="accent"
    min-height="calc(100vh - 200px)"
    class="pt-2"
  >
    <template>
      <b-breadcrumb :items="breadCrumbs"></b-breadcrumb>
    </template>
    <ContextualActionBar title="" />
    <BadgesSectionLinks :infoBadges="infoBadges" />
  </v-sheet>
</template>

<script>
import BadgesSectionLinks from "../components/core/BadgesSectionLinks.vue";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import connectionMixin from "@/mixins/connectionParams.js";

export default {
  name: "Home",

  components: {
    ContextualActionBar,
    BadgesSectionLinks,
  },
  mixins: [connectionMixin],
  data() {
    return {
      infoBadges: [
        {
          title: "Dashboard e Perimetro Schede",
          subtitle: "gestione dati di riepilogo Servizi",
          link: "/dashboardadmin",
          icon: "mdi-chart-bar",
        },
        {
          title: "Schede Operative e TAD",
          subtitle: "Visualizzazione schede operative",
          link: "/operatingcards",
          icon: "mdi-view-dashboard-outline",
        },
        {
          title: "Piano Operativo",
          subtitle: "Generazione piano operativo per GDC",
          link: "/operationalplan",
          icon: "mdi-clipboard-list-outline",
        },
      ],
      breadCrumbs: [
        {
          text: "Home",
          to: "/",
        },
      ],
    };
  },
  mounted() {
    this.accessLog();
  },
  methods: {
    async accessLog() {
      this.loading = true;
      try {
        let url = this.$env.value("API_HOST_STORE") + "/loginLog";
        const response = await this.$http.post(url, {}, this.opts);
        this.recordsRs = response.data;
        this.loading = false;
      } catch (error) {
        alert("Qualcosa è andato storto.");
        console.log(error);
      }
    },
  },
};
</script>
