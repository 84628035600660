var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex align-center justify-center",attrs:{"id":"scrolling-techniques-7"}},[_c('v-row',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-center"},_vm._l((_vm.infoBadges),function(info,index){return _c('v-card',{key:index,staticClass:"my-4 align-self-center",attrs:{"max-width":"550","disabled":info.title == 'Vista BS in GDC' ? true : false}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"72","color":"secondary"}},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v(" "+_vm._s(info.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" "+_vm._s(info.title)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(info.subtitle)+" "),(info.info)?_c('v-icon',{on:{"click":function($event){return _vm.updateDialogInfoExcel(true)}}},[_vm._v(_vm._s(info.info)+" ")]):_vm._e()],1)],1),_c('v-list-item-action',[(info.action == 'openFileDialog')?_c('v-btn',{staticClass:"text--primary",attrs:{"rounded":"","color":"secondary","elevation":"0","disabled":_vm.userProfile == 'readonly' && info.title == 'Piano Operativo'
                  ? true
                  : false},on:{"click":_vm.openFileDialog}},[_vm._v(" apri ")]):_vm._e(),(info.title == 'Scenario in corso')?_c('v-btn',{staticClass:"text--primary",attrs:{"rounded":"","color":"secondary","elevation":"0","disabled":_vm.activeplan.length == 0 && info.title == 'Scenario in corso'
                  ? true
                  : false},on:{"click":_vm.goToDetailPlan}},[_vm._v(" apri ")]):_vm._e(),(
                info.title != 'Scenario in corso' &&
                info.action != 'openFileDialog'
              )?_c('v-btn',{staticClass:"text--primary",attrs:{"rounded":"","color":"secondary","elevation":"0","to":info.link,"disabled":_vm.userProfile == 'readonly' && info.title == 'Piano Operativo'
                  ? true
                  : false}},[_vm._v(" apri ")]):_vm._e()],1)],1)],1)}),1)],1),_c('ModalInfoExcel',{attrs:{"dialogInfoExcel":_vm.openDialogInfoExcel},on:{"open-dialog-info":_vm.updateDialogInfoExcel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }