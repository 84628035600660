export default {
  data() {
    return {
      userProfile: "",
    };
  },
  computed: {},
  created() {},
  methods: {
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        this.userProfile = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("User");
        this.displayProgress = false;
      }
    },
  },
};
