<template>
  <div>
    <v-app-bar
      relative
      elevate-on-scrol
      flat
      scroll-target="#scrolling-techniques-7"
      color="accent"
      dark
    >
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-toolbar-title class="justify-center" v-bind="attrs" v-on="on">
            <span class="text-subtitle-1">{{ subtitle }}</span>
            <span>
              <span
                v-if="title_label"
                class="secondary--text"
                style="font-size: 14px"
              >
                {{ title_label }}&ensp;
              </span>
              <span style="font-size: 14px"> {{ title_value }}</span>
            </span>
          </v-toolbar-title>
        </template>
        <span class="text-subtitle-1">{{ subtitle }}</span>

        <span v-if="title_label" class="secondary--text">
          {{ title_label }}&ensp;
        </span>
        <span>{{ title_value }}</span>
      </v-tooltip>
      <span v-else>
        <span class="text-subtitle-1">{{ subtitle }}</span>
        <span>
          <span
            v-if="title_label"
            class="secondary--text"
            style="font-size: 14px"
          >
            {{ title_label }}&ensp;
          </span>
          <span style="font-size: 14px"> {{ title_value }}</span>
        </span></span
      >
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
      String,
    },
    subtitle: {
      default: "",
      String,
    },
    tooltip: {
      default: false,
      Boolean,
    },
  },
  computed: {
    title_label() {
      var t = this.title.split(":");
      return t[1] ? t[0] : "";
    },
    title_value() {
      var t = this.title.split(":");
      return t[1] ? t[1] : t[0];
    },
  },
  methods: {},
};
</script>
