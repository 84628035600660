export default {
  namespaced: true,

  state: () => ({
    activation: false,
  }),
  mutations: {
    ACTIVATE(state, value) {
      state.activation = value;
    },
  },
  actions: {
    activate({ commit }, value) {
      commit("ACTIVATE", value);
    },
  },
  getters: {
    getActivation: (state) => {
      return state.activation;
    },
  },
};
