<template>
  <v-container
    id="scrolling-techniques-7"
    class="d-flex align-center justify-center"
  >
    <v-row class="d-flex flex-row justify-center align-center"
      ><v-col class="d-flex flex-column justify-center align-center">
        <v-card
          v-for="(info, index) in infoBadges"
          :key="index"
          class="my-4 align-self-center"
          max-width="550"
          :disabled="info.title == 'Vista BS in GDC' ? true : false"
        >
          <v-list-item two-line>
            <v-list-item-avatar tile size="72" color="secondary">
              <v-icon x-large color="primary">
                {{ info.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                {{ info.title }}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{ info.subtitle }}
                <v-icon v-if="info.info" @click="updateDialogInfoExcel(true)"
                  >{{ info.info }}
                </v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="info.action == 'openFileDialog'"
                rounded
                color="secondary"
                class="text--primary"
                elevation="0"
                @click="openFileDialog"
                :disabled="
                  userProfile == 'readonly' && info.title == 'Piano Operativo'
                    ? true
                    : false
                "
              >
                apri
              </v-btn>
              <v-btn
                v-if="info.title == 'Scenario in corso'"
                rounded
                color="secondary"
                class="text--primary"
                elevation="0"
                @click="goToDetailPlan"
                :disabled="
                  activeplan.length == 0 && info.title == 'Scenario in corso'
                    ? true
                    : false
                "
              >
                apri
              </v-btn>
              <v-btn
                v-if="
                  info.title != 'Scenario in corso' &&
                  info.action != 'openFileDialog'
                "
                rounded
                color="secondary"
                class="text--primary"
                elevation="0"
                :to="info.link"
                :disabled="
                  userProfile == 'readonly' && info.title == 'Piano Operativo'
                    ? true
                    : false
                "
              >
                apri
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <ModalInfoExcel
      @open-dialog-info="updateDialogInfoExcel"
      :dialogInfoExcel="openDialogInfoExcel"
    ></ModalInfoExcel>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import connectionMixin from "@/mixins/connectionParams.js";
import planIsActive from "@/mixins/planIsActive.js";
import ModalInfoExcel from "@/components/ModalInfoExcel.vue";
import profileUser from "@/mixins/profileUser.js";

export default {
  name: "BadgesSectionLinks",
  mixins: [connectionMixin, planIsActive, profileUser],
  components: { ModalInfoExcel },
  props: {
    infoBadges: {
      default: [],
      Array,
    },
  },
  data: () => ({
    loading: false,
    activeplan: [],
    openDialogInfoExcel: false,
  }),
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),
  },
  mounted() {
    this.getUserProfileBadges();
  },
  methods: {
    ...mapActions("operationalModule", ["activate"]),
    openFileDialog() {
      localStorage.setItem("planIsActive", false);
      this.activate(false);
      this.disableAlert = false;
      document.getElementById("text-reader").value = "";
      document.getElementById("text-reader").click();
    },
    goToDetailPlan() {
      this.activate(true);
      localStorage.setItem("planIsActive", true);
      this.$router.push("/opplanimpactandprogress/" + this.activeplan[0]);
    },
    updateDialogInfoExcel(value) {
      this.openDialogInfoExcel = value;
    },
    async getUserProfileBadges() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        if (response.data !== "readonly") {
          this.getActivePlan();
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
  },
};
</script>

<style scoped>
#scrolling-techniques-7 {
  height: calc(100vh - 200px);
}
</style>
