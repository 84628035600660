import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/operatingcards",
    name: "OperatingCards",
    component: () => import("../views/OperatingCards.vue"),
  },
  {
    path: "/dashboardadmin",
    name: "Dashboardadmin",
    component: () => import("../views/Dashboardadmin.vue"),
  },
  {
    path: "/operatingcarddetails/:id/:edit",
    name: "OperatingCardDetails",
    component: () => import("../views/OpCardDetails.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/operatingcarddetailsRS/:id",
    name: "OperatingCardDetailsRS",
    component: () => import("../views/OpCardDetailsRS.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/operationalplan",
    name: "OperationalPlan",
    component: () => import("../views/OperationalPlan.vue"),
  },
  {
    path: "/opplanimpact",
    name: "OpPlanImpact",
    component: () => import("../views/OpPlanImpact.vue"),
    beforeEnter: (to, from, next) => {
      let planActive = store.getters["operationalModule/getActivation"];
      if (planActive && from.name != "OpPlanFailoverGroups") {
        next("/operationalplan");
      } else if (planActive && from.name == "OpPlanFailoverGroups") {
        next("/opplanimpactandprogress");
      } else {
        next();
      }
    },
  },
  {
    path: "/opplanimpact/:planId",
    name: "OpPlanImpactId",
    component: () => import("../views/OpPlanImpact.vue"),
    beforeEnter: (to, from, next) => {
      let planActive = store.getters["operationalModule/getActivation"];
      if (planActive && from.name != "OpPlanFailoverGroups") {
        next("/operationalplan");
      } else if (planActive && from.name == "OperationalPlan") {
        next("/opplanimpactandprogress");
      } else if (planActive && from.name == "OpPlanImpactAndProgress") {
        next("/operationalplan");
      } else if (planActive && from.name == "OpPlanFailoverGroups") {
        next("/opplanimpactandprogress");
      } else {
        next();
      }
    },
  },
  {
    path: "/opplanimpactandprogress/:planId",
    name: "OpPlanImpactAndProgress",
    component: () => import("../views/OpPlanImpactAndProgress.vue"),
    props: (route) => ({
      ...route.params,
    }),
    /*   beforeEnter: (to, from, next) => {
      let planActive = store.getters["operationalModule/getActivation"];
      console.log("QUI",planActive)
      if (!planActive) {
        next("/operationalplan");
      } else {
        next();
      }
    }, */
  },
  {
    path: "/opplandefault",
    name: "OpPlanDefault",
    component: () => import("../views/OpPlanDefault.vue"),
  },
  {
    path: "/opplanfailovergroups",
    name: "OpPlanFailoverGroups",
    component: () => import("../views/OpPlanFailoverGroups.vue"),
    beforeEnter: (to, from, next) => {
      let planActive = store.getters["operationalModule/getActivation"];
      if (planActive && from.name == "OpPlanImpactAndProgress") {
        next("/operationalplan");
      } else {
        next();
      }
    },
  },
  {
    path: "/opplanfailovergroups/:planId",
    name: "OpPlanFailoverGroupsId",
    component: () => import("../views/OpPlanFailoverGroups.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/opplanfailovergroupsdetails/:planId",
    name: "OpPlanFailoverGroupsDetails",
    component: () => import("../views/OpPlanFailoverGroupsDetails.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/opEditServiceProgress",
    name: "OpEditServiceProgress",
    component: () => import("../views/OpEditServiceProgress.vue"),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: "/help",
    component: {
      template:
        // '<iframe src="/static/help/index.html" frameborder="0" width="100%" height="100%"></iframe>',
        '<h1>HELP</h1>',
    },
  },
];
const router = new VueRouter({
  history: true,
  base: process.env.BASE_URL,
  routes,
});

export default router;
