import { mapGetters } from "vuex";
export default {
  data() {
    return {
      apihost: "",
      headers: "",
      opts: "",
      optsXls: "",
      optsXlsPOST: "",
    };
  },
  computed: {
    apiConf: {
      get() {
        return this.getApi();
      },
    },
    tokenKey: {
      get() {
        return this.getToken();
      },
    },
  },
  created() {
    this.apihost = this.apiConf.apihost;
    this.optsXls = {
      headers: {
        Authorization: "Bearer " + this.tokenKey,
        Accept: "application/octet-stream",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      responseType: "arraybuffer",
      timeout: 0,
    };

    this.optsXlsPOST = {
      headers: {
        Authorization: "Bearer " + this.tokenKey,
        Accept: "application/octet-stream",
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
      timeout: 0,
    };

    this.opts = {
      headers: {
        Authorization: "Bearer " + this.tokenKey,
      },
      timeout: 0,
    };
  },
  methods: {
    ...mapGetters("apiModule", ["getApi"]),

    ...mapGetters("loginModule", ["getToken"]),
  },
};
